// Import node modules
import restoreScroll from 'redux-first-router-restore-scroll'
import querySerializer from 'query-string'
import { getIn } from 'timm'
import { pathToAction, actionToPath } from 'redux-first-router'
// Import utils and ducks
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { CookieDuc } from 'ui-tdm-app/modules/App/cookieDuc'
import { AUTH_COOKIE_KEYS } from 'ui-lib/utils/config'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import baseRoutes, {
	getActiveUserDashboardAction,
} from 'ui-tdm-app/routes/base'
import { getRelativeUrlFromAbsoluteUrl } from 'ui-tdm-app/utils/helpers'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import {
	isDomainChange,
	getOnBeforeChangeUrl,
} from 'ui-lib/utils/routePathConfig'

const issueAuthLoginRedirect = dispatch => {
	dispatch(
		MainRouteDuc.creators.redirect(
			MainRouteDuc.types.AUTH,
			{
				action: 'sign-in',
			},
			{
				redirect_to: getRelativeUrlFromAbsoluteUrl(
					window.location.href
				),
			}
		)
	)
}

// Redirect user to dashboard when user trying to use non-authorized routes when they authenticated for eg:  signup, login
const issueDashboardRedirect = dispatch => {
	if (isIframeForTeamsApp()) {
		dispatch(
			MainRouteDuc.creators.redirect(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER
			)
		)
	} else {
		dispatch(
			MainRouteDuc.creators.redirect(MainRouteDuc.types.DASHBOARD, {
				action: '/',
			})
		)
	}
}
/**
 * @description: Populates the 'cookies' slice in redux store with region.
 * Redirects to region selection page if no region cookie is found.
 * @param {object} dispatch
 * @param {object} state
 * @param {object} action
 */

export const populateStoreFromCookies = (dispatch, store, action = {}) => {
	const { getState } = store
	const {
		location: { type: typeFromStore },
	} = getState()
	const { type } = action || {}

	const targetType = type || typeFromStore

	if (!targetType) return

	// fetch state from localStorage into redux
	CookieDuc.options.helpers.fetchAuthExpiries(dispatch)

	if (
		targetType &&
		targetType !== MainRouteDuc.types.AUTH &&
		targetType !== MainRouteDuc.types.ONBOARD &&
		targetType !== MainRouteDuc.types.POLICY
	) {
		// check if expiry on tokens are reached so we need to validate them.
		const activeTokens = CookieDuc.selectors.getActiveAuthTokens(getState())

		if (
			!activeTokens.length ||
			!activeTokens.includes(AUTH_COOKIE_KEYS.REFRESH_TOKEN) ||
			!activeTokens.includes(AUTH_COOKIE_KEYS.ID_TOKEN)
		) {
			issueAuthLoginRedirect(dispatch)

			return true
		}

		// based on the tokens refresh them.
		if (
			!activeTokens.includes(AUTH_COOKIE_KEYS.ACCESS_TOKEN) &&
			targetType !== MainRouteDuc.types.AUTH &&
			targetType !== MainRouteDuc.types.POLICY
		) {
			// refresh tokens
			dispatch(AuthDuc.creators.initiateRefreshToken())
		}

		// fetch the client ID into state
		const clientID = AuthDuc.selectors.getClientID(getState())

		if (!clientID) {
			dispatch(
				CookieDuc.creators.getCookie({
					cookieName: AUTH_COOKIE_KEYS.CLIENT_ID,
				})
			)
		}

		const { id: userID } = AuthDuc.selectors.getUserProfile(getState())

		if (!userID) dispatch(AuthDuc.creators.fetchUserProfile(clientID))
	} else if (targetType && targetType === MainRouteDuc.types.AUTH) {
		// check if the user is already logged in, then route them to dashboard
		const activeTokens = CookieDuc.selectors.getActiveAuthTokens(getState())

		if (
			activeTokens.includes(AUTH_COOKIE_KEYS.REFRESH_TOKEN) &&
			activeTokens.includes(AUTH_COOKIE_KEYS.ID_TOKEN)
		) {
			issueDashboardRedirect(dispatch)

			const { allowed } = AuthDuc.selectors.getCurrentUserRoles(
				getState()
			)

			// send them to dashboard
			if (allowed && allowed.type) {
				dispatch(getActiveUserDashboardAction(allowed))
			}
		}
	} else if (
		(targetType && targetType === MainRouteDuc.types.ONBOARD) ||
		(targetType && targetType === MainRouteDuc.types.POLICY)
	) {
		const activeTokens = CookieDuc.selectors.getActiveAuthTokens(getState())
		if (
			activeTokens.includes(AUTH_COOKIE_KEYS.REFRESH_TOKEN) &&
			activeTokens.includes(AUTH_COOKIE_KEYS.ID_TOKEN)
		) {
			if (targetType !== MainRouteDuc.types.POLICY)
				issueDashboardRedirect(dispatch)
		}
	}
}

/**
 * Syncs and keeps the query params in store state on every route change.
 * @param {*} store
 * @param {*} action
 */
const populateQueryParamsFromLocation = (dispatch, action) => {
	const query = getIn(action, ['meta.location.current.query'])
	if (!query) {
		dispatch(AppDuc.creators.syncQueryParams(query))
	}
}

export default {
	querySerializer,
	// This is called before every route dispatch
	onBeforeChange: (dispatch, _, { action, extra }) => {
		const store = extra.getStore()
		if (
			isDomainChange(
				getIn(action, ['type']),
				getIn(action, ['payload', 'rootModule'])
			)
		) {
			getOnBeforeChangeUrl(action)
		} else {
			if (!store) return // store instance couldn't be found.

			populateStoreFromCookies(dispatch, store, action) // use the dispatch from param since it controlls redux first router

			// set the query params in the url and sync them.
			populateQueryParamsFromLocation(dispatch, action)
		}
	},
	onAfterChange: (dispatch, _, { action, extra }) => {
		const store = extra.getStore()
		if (!store) return // store instance couldn't be found.

		const {
			location: { type: typeFromStore },
		} = store.getState()
		const { type } = action || {}

		const targetType = type || typeFromStore

		if (!targetType) return

		// validate the route if its not auth ones
		// added terms n policy and onboard pages as dont need to validate routes here should be open to all
		if (
			targetType !== MainRouteDuc.types.AUTH &&
			targetType !== MainRouteDuc.types.ONBOARD &&
			targetType !== MainRouteDuc.types.POLICY
		)
			dispatch(AuthDuc.creators.validateUserRouteChange(type))
	},
	restoreScroll: restoreScroll({
		shouldUpdateScroll: (prev, locationState) => {
			// add a hack to handle our
			const forceScrolltoTop = getIn(locationState, [
				'payload',
				'forceScrolltoTop',
			])
			const forcePreventScrollToTop = getIn(locationState, [
				'payload',
				'forcePreventScrollToTop',
			])

			if (forcePreventScrollToTop) return false

			if (forceScrolltoTop) return true

			if (prev.type === locationState.type) {
				return false
			}

			if (!prev.kind || !locationState.kind) {
				return false
			}

			if (locationState.payload && locationState.payload.coords) {
				// return an array of xy coordinates to scroll there
				return [
					locationState.payload.coords.x,
					locationState.payload.coords.y,
				]
			}

			// Accurately emulate the default behavior of scrolling to the top on new history
			// entries, and to previous positions on pop state + hash changes.
			// This is the default behavior, and this callback is not needed if this is all you want.
			return true
		},
	}),
}

export const getActionBasedonUrl = url => pathToAction(url, baseRoutes)

export const getPathBasedOnAction = (type, payload = {}, query = {}) =>
	actionToPath(
		{
			type,
			payload,
			query,
		},
		baseRoutes
	)
